import { CompleteSignUpRequestBody } from '../api/_base/generated/data-contracts';
import { AreaOption, SignUpFormData } from '../types/onBoarding';

import { individualEntityType } from './constants';

export const getDataObject = (formData: SignUpFormData) => {
  const completeSignupParams: CompleteSignUpRequestBody = {
    email: formData.email,
    password: formData.password,
    firstName: formData.firstName,
    lastName: formData.lastName,
    initialTermsAndConditionsAcceptedOn: formData.initialTermsAndConditionsAcceptedOn,
    companyName: formData.companyName!,
    vestingEntityName: formData.vestingEntityName!,
    vestingEntityType: formData.vestingEntityType!,
    doesVestUnderPersonalName: formData.vestingEntityType === individualEntityType,
    phone: formData.phone,
    uuidInvestorPortalUser: formData.uuidInvestorPortalUser,
    fixAndFlip: formData.fixAndFlip,
    buyAndHold: formData.buyAndHold,
    shortTermRental: formData.shortTermRental,
    wholesale: formData.wholesale,
    otherInvestment: formData.otherInvestment,
    primaryOperatingMarketOnboarding: formData.primaryMarket[0].sfId || '',
    noOfTxnNext12MonthOnboarding: +formData.nextTwelve,
    noTxnLast12MonthOnboarding: +formData.lastTwelve,
    marketingReferralSource: formData.marketingReferralSource,
    autoApprovalToken: formData.autoApprovalToken,
    termsAndConditionsAcceptedOn: formData.termsAndConditionsAcceptedOn,
    utmSource: formData.utmSource ? formData.utmSource : undefined,
    utmMedium: formData.utmMedium ? formData.utmMedium : undefined,
    utmCampaign: formData.utmCampaign ? formData.utmCampaign : undefined,
    utmTerm: formData.utmTerm ? formData.utmTerm : undefined,
    utmContent: formData.utmContent ? formData.utmContent : undefined,
    advisor: formData.advisor ? formData.advisor : undefined,
    utmReferrer: formData.utmReferrer ? formData.utmReferrer : undefined,
    additionalOperatingMarketOnboarding: '',
    smsOptOut: formData.smsOptOut,
    rehabTypes: formData.rehabTypes,
    financialSources: formData.financialSources,
    investmentTypes: formData.investmentTypes,
    contactType: formData.contactType
  };

  if (formData.secondaryMarkets.length) {
    let secondaryArea = formData.secondaryMarkets[0].sfId;
    formData.secondaryMarkets.forEach((val: AreaOption, index: number) => {
      if (index) {
        secondaryArea = secondaryArea + ';' + val.sfId;
      }
    });
    completeSignupParams.additionalOperatingMarketOnboarding = secondaryArea || '';
  }

  return completeSignupParams;
};
