import queryString, { ParsedQuery } from 'query-string';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

import commonStyles from '../../common/modal.module.scss';
import { connectedInvestorsReferralSource } from '../../helpers/constants';
import { useAutoApprovalToken } from '../../hooks/store/misc';
import { useConfirmDialog } from '../../hooks/useConfirmDialog';
import { useModalByName } from '../../hooks/useModalByName';
import { captureException } from '../../logging';
import { selectReferralInfo } from '../../store/misc';
import { SignUpFormData, SignupWizardStepName } from '../../types/onBoarding';
import { ModalCloseButton } from '../Modal/ModalCloseButton';

import ConfirmModalClose from './ConfirmModalClose';
import { AccessVerificationFailedStep } from './QuestionSets/AccessVerificationFailedStep';
import CreateAccount from './QuestionSets/CreateAccount';
import GeneralError from './QuestionSets/GeneralError';
import IdentityVerificationFailedSuccessStep from './QuestionSets/IdentityVerificationFailedSuccessStep';
import Market from './QuestionSets/Market';
import Story from './QuestionSets/Story';
import Strategy from './QuestionSets/Strategy';
import SuccessPage from './QuestionSets/SuccessPage';
import Terms from './QuestionSets/Terms';
import VerifyEmailStep from './QuestionSets/VerifyEmailStep';
import { VerifyPhoneStep } from './QuestionSets/VerifyPhoneStep';
import { VerifySocialStep } from './QuestionSets/VerifySocialStep';
import styles from './SignUp.module.scss';

interface OnBoardProps {
  close: () => void;
  isOpen: boolean;
}

export interface ReferralQuery {
  referral?: string;
}

export interface UTMQuery {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
  advisor?: string;
  utmReferrer?: string;
}

export type SignupQuery = UTMQuery & ReferralQuery;

const SignupWizardStepOrder: SignupWizardStepName[] = [
  'createAccountStep',
  'investorStoryStep',
  'investmentStrategy',
  'verifyPhoneStep',
  'verifyEmailStep',
  'targetMarketsStep',
  'termsStep',
  'verifySocialStep',
  'accessVerificationFailedStep',
  'accessVerificationEmailFailedStep',
  'generalErrorStep'
];

const confirmDialogEnabledSteps: SignupWizardStepName[] = [
  'investorStoryStep',
  'investmentStrategy',
  'verifyPhoneStep',
  'verifyEmailStep',
  'targetMarketsStep',
  'termsStep',
  'verifySocialStep'
];

const SignUpForm = (props: OnBoardProps) => {
  const initialFormData: SignUpFormData = {
    email: '',
    firstName: '',
    lastName: '',
    marketingReferralSource: '',
    accountId: '',
    uuidInvestorPortalUser: '',
    phone: '',
    password: '',
    fixAndFlip: 0,
    buyAndHold: 0,
    shortTermRental: 0,
    wholesale: 0,
    otherInvestment: 0,
    investmentTotal: 0,
    lastTwelve: '',
    nextTwelve: '',
    companyName: '',
    financialSources: [],
    contactType: 'Flipper',
    investmentTypes: [],
    rehabTypes: [],
    vestingEntityName: '',
    vestingEntityType: '',
    primaryMarket: [],
    secondaryMarkets: [],
    termsAndConditionsAcceptedOn: '',
    story: '',
    autoApprovalToken: '',
    additionalOperatingMarketOnboarding: '',
    primaryOperatingMarketOnboarding: '',
    utmSource: undefined,
    utmMedium: undefined,
    utmCampaign: undefined,
    utmTerm: undefined,
    utmContent: undefined,
    advisor: undefined,
    utmReferrer: undefined,
    smsOptOut: true,
    initialTermsAndConditionsAcceptedOn: ''
  };

  const { close, isOpen } = props;
  const [stepIndex, setStepIndex] = useState(0);
  const [stepCount] = useState(SignupWizardStepOrder.length - 1);
  const step = SignupWizardStepOrder[stepIndex];

  const [success, setSuccess] = useState(false);
  const [isUserIdentityVerified, setIsUserIdentityVerified] = useState(false);
  const [isPrimaryMarketActive, setIsPrimaryMarketActive] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const [autoApprovalToken] = useAutoApprovalToken();

  const accountCreation = useForm({});
  const signUpForm = useForm({});

  const [, showLoginModal] = useModalByName('LoginModal');
  const [, showForgotPasswordModal] = useModalByName('ForgotPassword');
  const [isConfirmDialogShowing, showConfirmDialog, hideConfirmDialog] = useConfirmDialog();

  const stepNavigate = (direction: 'next' | 'back') => {
    const mod = direction === 'next' ? 1 : -1;
    const nextStep = mod + stepIndex;

    if (nextStep <= stepCount && nextStep >= 0) {
      setStepIndex(nextStep);
    }
  };

  const jumpStep = (stepName: SignupWizardStepName) => {
    const index = SignupWizardStepOrder.indexOf(stepName);
    setStepIndex(index);
  };

  const updateFormData = (newFormData: Partial<SignUpFormData>) => {
    // setFormData in a callback to avoid sync issues
    setFormData((formData) => ({ ...formData, ...newFormData }));
  };

  const referral = useSelector(selectReferralInfo);

  useEffect(() => {
    const query: SignupQuery = queryString.parse(window.location.search) as ParsedQuery<
      keyof SignupQuery
    >;
    const { utmSource, utmMedium, utmCampaign, utmTerm, utmContent, advisor, utmReferrer } = query;

    if (referral) {
      try {
        const prefillFormData = {
          firstName: referral.firstName,
          lastName: referral.lastName,
          email: referral.email,
          phone: referral.phone,
          marketingReferralSource: connectedInvestorsReferralSource
        };
        updateFormData({ ...prefillFormData });
      } catch (e: unknown) {
        // Error thrown by jwt.decode
        console.error(e);
        captureException(e);
      }
    }

    updateFormData({
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
      advisor,
      utmReferrer
    });
  }, [referral]);

  useEffect(() => {
    const signupUnloadHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault(); // html specification is to call preventDefault here.
      // Also need to set the returnValue for chrome.
      e.returnValue = 'Exiting now will require restarting the signup process.';
    };

    if (isOpen) window.addEventListener('beforeunload', signupUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', signupUnloadHandler);
    };
  }, [isOpen]);

  const isConfirmDialogEnabled = !success && confirmDialogEnabledSteps.includes(step);

  const handleConfirmCloseSignupModal = () => {
    hideConfirmDialog();
    close();
    setStepIndex(0);
  };

  const handleCloseSignupModal = () => {
    isConfirmDialogEnabled ? showConfirmDialog() : close();
  };

  return (
    <Modal
      id="signupModal"
      isOpen={isOpen}
      toggle={handleCloseSignupModal}
      wrapClassName="customDialogue"
      className={styles.signupModal}
    >
      <ModalBody id="model-body">
        {isConfirmDialogShowing && (
          <ConfirmModalClose
            onCancel={hideConfirmDialog}
            onConfirm={handleConfirmCloseSignupModal}
            isDialogShowing={isConfirmDialogShowing}
            isCentered={false}
          />
        )}
        {success && (
          <>
            <ModalCloseButton onClick={handleCloseSignupModal} />
            {!isUserIdentityVerified && (
              <IdentityVerificationFailedSuccessStep onClose={handleCloseSignupModal} />
            )}
            {isUserIdentityVerified && (
              <div className={commonStyles.modalInnerWrap}>
                <SuccessPage
                  handleCloseSignupModal={handleCloseSignupModal}
                  initialFormData={formData}
                  isPrimaryMarketActive={isPrimaryMarketActive}
                />
              </div>
            )}
          </>
        )}
        {!success && (
          <div className={commonStyles.modalInnerWrap}>
            <ModalCloseButton onClick={handleCloseSignupModal} />
            {/* {step !== 'createAccountStep' && (
              <ProgressTracker setLength={stepCount} stage={stepIndex} />
            )} */}
            <FormProvider {...accountCreation}>
              {step === 'createAccountStep' && (
                <CreateAccount
                  updateFormData={updateFormData}
                  stepNavigate={stepNavigate}
                  showLoginModal={showLoginModal}
                  showForgotPasswordModal={showForgotPasswordModal}
                  autoApprovalToken={autoApprovalToken}
                  initialFormData={formData}
                />
              )}
            </FormProvider>
            <FormProvider {...signUpForm}>
              {step === 'investorStoryStep' && (
                <Story
                  updateFormData={updateFormData}
                  stepNavigate={stepNavigate}
                  initialFormData={formData}
                />
              )}
              {step === 'investmentStrategy' && (
                <Strategy
                  updateFormData={updateFormData}
                  stepNavigate={stepNavigate}
                  initialFormData={formData}
                />
              )}
              {step === 'verifyPhoneStep' && (
                <VerifyPhoneStep
                  stepNavigate={stepNavigate}
                  initialFormData={formData}
                  jumpStep={jumpStep}
                  updateFormData={updateFormData}
                />
              )}
              {step === 'verifyEmailStep' && (
                <VerifyEmailStep
                  jumpStep={jumpStep}
                  updateFormData={updateFormData}
                  stepNavigate={stepNavigate}
                  initialFormData={formData}
                />
              )}
              {step === 'targetMarketsStep' && (
                <Market
                  updateFormData={updateFormData}
                  stepNavigate={stepNavigate}
                  initialFormData={formData}
                />
              )}
              {step === 'termsStep' && (
                <Terms
                  jumpStep={jumpStep}
                  updateFormData={updateFormData}
                  stepNavigate={stepNavigate}
                  initialFormData={formData}
                  setSuccess={setSuccess}
                  setIsPrimaryMarketActive={setIsPrimaryMarketActive}
                  setIsUserIdentityVerified={setIsUserIdentityVerified}
                />
              )}
              {step === 'verifySocialStep' && (
                <VerifySocialStep initialFormData={formData} jumpStep={jumpStep} />
              )}
              {step === 'accessVerificationFailedStep' && (
                <AccessVerificationFailedStep onClose={handleCloseSignupModal} />
              )}
              {step === 'accessVerificationEmailFailedStep' && (
                <AccessVerificationFailedStep onClose={handleCloseSignupModal} emailFailed />
              )}
              {step === 'generalErrorStep' && <GeneralError onClose={handleCloseSignupModal} />}
            </FormProvider>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SignUpForm;
